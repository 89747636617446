import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Initialize Facebook Pixel
export const initFacebookPixel = () => {
    /* eslint-disable */
    !function (f, b, e, v, n, t, s) {
        if (f.fbq) return; n = f.fbq = function () {
            n.callMethod ?
                n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        };
        if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
        n.queue = []; t = b.createElement(e); t.async = !0;
        t.src = v; s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s)
    }(window, document, 'script',
        'https://connect.facebook.net/en_US/fbevents.js');
    /* eslint-enable */

    window.fbq('init', process.env.REACT_APP_FB_PIXEL_ID);
    window.fbq('track', 'PageView');
};

// Hook to track page views
export const useFacebookPixel = () => {
    const location = useLocation();

    useEffect(() => {
        if (typeof window !== 'undefined' && window.fbq) {
            window.fbq('track', 'PageView');
        }
    }, [location]);
};


// src/utils/facebookEvents.js
export const FB_EVENTS = {
    REGISTER: 'CompleteRegistration',
    CHECKOUT_START: 'InitiateCheckout',
    SUBSCRIBE: 'Subscribe'
};

// Track registration events
export const trackRegistration = (registrationData = {}) => {
    if (typeof window !== 'undefined' && window.fbq) {
        window.fbq('track', FB_EVENTS.REGISTER, {
            ...registrationData,
            currency: 'USD',
            status: 'registered'
        });
    }
};

// Track checkout initiation
export const trackCheckoutStart = (checkoutData = {}) => {
    if (typeof window !== 'undefined' && window.fbq) {
        window.fbq('track', FB_EVENTS.CHECKOUT_START, {
            ...checkoutData,
            currency: 'USD'
        });
    }
};

// Track successful subscription
export const trackSubscription = (subscriptionData = {}) => {
    if (typeof window !== 'undefined' && window.fbq) {
        window.fbq('track', FB_EVENTS.SUBSCRIBE, {
            ...subscriptionData,
            currency: 'USD',
            value: subscriptionData.amount || 0
        });
    }
};
