import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import emailjs from '@emailjs/browser';
import Cookies from "universal-cookie";
import SuccesModal from './SuccesModal';
import { useTranslation } from 'react-i18next';
import Loader from './Loader';

export default function SendFeedbackModal() {
    const [open, setOpen] = useState(true)
    const { t } = useTranslation();
    const [feedbackSent, setFeedbackSent] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true)
        const cookies = new Cookies();
        emailjs.send(process.env.REACT_APP_FEEDBACK_SERVICE_ID, process.env.REACT_APP_FEEDBACK_TEMPLATE_ID, {
            user_id: cookies.get("user_id"),
            app: process.env.REACT_APP_NAME,
            feedback: e.target.feedback.value,
        }, process.env.REACT_APP_FEEDBACK_PUBLIC_KEY)
            .then((result) => {
                setIsLoading(false)
                setFeedbackSent(true)
            }).
            catch((error) => {
                setIsLoading(false)
                setOpen(false)
            })
    };

    return (
        <Transition.Root show={open} as={Fragment} className="z-50">
            <Dialog as="div" className="relative" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-50 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-lime-500 focus:ring-offset-2"
                                        onClick={() => setOpen(false)}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>

                                <form onSubmit={handleSubmit}>
                                    <div className="sm:flex sm:items-start">
                                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
                                            <ExclamationTriangleIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
                                        </div>
                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                                            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                                {t('FeedbackModal.ModalTitle')}
                                            </Dialog.Title>
                                            {feedbackSent ?
                                                <p className="block text-base font-medium text-gray-700">
                                                    {t('FeedbackModal.FeedbackSuccessful')}
                                                </p>
                                                :
                                                <div className=' mt-2'>
                                                    <label htmlFor="feedback" className="block text-sm font-medium text-gray-700">
                                                        {t('FeedbackModal.ModalDescription')}
                                                    </label>
                                                    <div className="mt-1">
                                                        <textarea
                                                            rows={4}
                                                            name="feedback"
                                                            id="feedback"
                                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-lime-500 focus:ring-lime-500 sm:text-sm"
                                                        />
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                        {!feedbackSent &&
                                            <button
                                                type="submit"
                                                className="inline-flex w-full justify-center rounded-md border border-transparent bg-lime-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-lime-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"

                                            >
                                                {isLoading ? <Loader /> : "Submit Feedback"}
                                            </button>}
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-lime-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                                            onClick={() => setOpen(false)}
                                        >
                                            {t('FeedbackModal.CloseModal')}
                                        </button>
                                    </div>
                                </form>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )

};

