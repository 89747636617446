import React, { useState } from "react";
import BillingController from "./Payments/BillingController";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function UserSettings({ ...children }) {
  const [mountedSettings, setMoutedSetting] = useState(children.children);
  const { t } = useTranslation();
  const [mountedSettingsName, setMoutedSettingName] = useState(
    children.tabName
  );
  return (

    <div className="h-full">
      <div className="flex flex-1 flex-col md:pl-64 h-full">
        <main className="flex-1 h-full">
          <div className="py-6  h-full">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8  h-full">
              <div className=" pb-5 sm:pb-0 ">
                <h3 className="text-2xl font-medium leading-6 text-white">
                  {t("UserSettings.Settings")}
                </h3>
                <div className="mt-3 sm:mt-4">
                  <div className="sm:hidden">
                    <label htmlFor="current-tab" className="sr-only">
                      {t("UserSettings.SettingsSelectTab")}
                    </label>
                    <select
                      id="current-tab"
                      name="current-tab"
                      className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-lime-500 focus:outline-none focus:ring-lime-500 sm:text-sm"
                    >
                      <option>{t("UserSettings.PlansTab")}</option>
                    </select>
                  </div>
                  <div className="hidden sm:block">
                    <nav className="-mb-px flex space-x-8">
                      <a
                        href="/view_subscriptions"
                        className={
                          mountedSettingsName == t("UserSettings.PlansTabName")
                            ? "border-lime-500 text-lime-600 whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm"
                            : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm"
                        }
                      >
                        {t("UserSettings.PlansTab")}
                      </a>
                      <a
                        href="/account_settings"
                        className={
                          mountedSettingsName == t("UserSettings.AccountTabName")
                            ? "border-lime-500 text-lime-600 whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm"
                            : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm"
                        }
                      >
                        {t("UserSettings.AccountTabName")}
                      </a>
                    </nav>
                  </div>
                </div>
                {mountedSettings}
              </div>
            </div>
            <div className="mx-0 w-full px-4 sm:px-6 md:px-8 mt-10"></div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default UserSettings;
