import React, { createContext, useContext, useState } from 'react';

// Create the context
const CreditsContext = createContext();

// Create the provider component
export const CreditsProvider = ({ children }) => {
    const [credits, setCredits] = useState(null);

    const addCredits = (amount) => {
        setCredits(prev => prev + amount);
    };

    const removeCredits = (amount) => {
        setCredits(prev => Math.max(0, prev - amount));
    };

    const setCreditsValue = (amount) => {
        setCredits(Math.max(0, amount));
    };

    const value = {
        credits,
        addCredits,
        removeCredits,
        setCreditsValue
    };

    return (
        <CreditsContext.Provider value={value}>
            {children}
        </CreditsContext.Provider>
    );
};

// Custom hook for using credits
export const useCredits = () => {
    const context = useContext(CreditsContext);
    if (context === undefined) {
        throw new Error('useCredits must be used within a CreditsProvider');
    }
    return context;
};

// Example components showing usage
export const CreditsDisplay = () => {
    const { credits } = useCredits();
    return (
        <div className="p-4 bg-gray-100 rounded">
            <p className="text-lg font-bold">Credits: {credits}</p>
        </div>
    );
};

export const CreditsPurchaseButton = ({ amount = 10, cost = "$1.99" }) => {
    const { addCredits } = useCredits();

    const handlePurchase = () => {
        // Here you would typically integrate with a payment system
        // For this example, we'll just add the credits directly
        addCredits(amount);
    };

    return (
        <button
            onClick={handlePurchase}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
            Buy {amount} Credits for {cost}
        </button>
    );
};

export const CreditsSpendButton = ({ amount = 1, action = "Use Feature" }) => {
    const { credits, removeCredits } = useCredits();

    const handleSpend = () => {
        if (credits >= amount) {
            removeCredits(amount);
            // Perform the credited action here
            return true;
        }
        return false;
    };

    return (
        <button
            onClick={handleSpend}
            disabled={credits < amount}
            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 disabled:bg-gray-400"
        >
            {action} ({amount} {amount === 1 ? 'credit' : 'credits'})
        </button>
    );
};
