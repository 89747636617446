import { XIcon } from 'lucide-react';
import React, { useState, useEffect, useRef } from 'react';
import { useSwipeable } from 'react-swipeable';

const MyImageCarousel = ({ generatedImages, handleCarouselVisibility, imageIndex }) => {
    const [currentIndex, setCurrentIndex] = useState(imageIndex || 0);
    const [flattenedImages, setFlattenedImages] = useState([]);
    const thumbnailRef = useRef(null);

    useEffect(() => {
        const images = generatedImages?.reduce((acc, imageGroup) => [...acc, ...imageGroup.media], []);
        setFlattenedImages(images);
    }, [generatedImages]);

    useEffect(() => {
        centerThumbnail(currentIndex);
    }, [currentIndex, flattenedImages]);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'ArrowLeft') {
                goToPrevious();
            } else if (e.key === 'ArrowRight') {
                goToNext();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [currentIndex, flattenedImages.length]);

    const goToPrevious = () => {
        setCurrentIndex(prevIndex => prevIndex > 0 ? prevIndex - 1 : flattenedImages.length - 1);
    };

    const goToNext = () => {
        setCurrentIndex(prevIndex => prevIndex < flattenedImages.length - 1 ? prevIndex + 1 : 0);
    };

    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => goToNext(),
        onSwipedRight: () => goToPrevious(),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    const downloadImage = async () => {
        try {
            const imageUrl = flattenedImages[currentIndex].original_url;

            // Fetch the image as a blob
            const response = await fetch(imageUrl);
            const blob = await response.blob();

            // Get the filename from the URL
            const urlParts = imageUrl.split('/');
            const fileName = urlParts[urlParts.length - 1];

            // Create a blob URL
            const blobUrl = window.URL.createObjectURL(blob);

            // Create a temporary link element
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = fileName; // Use the original filename

            // Append to document, click, and cleanup
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // Revoke the blob URL to free up memory
            window.URL.revokeObjectURL(blobUrl);
        } catch (error) {
            console.error('Error downloading image:', error);
        }
    };

    const changeImage = (index) => {
        setCurrentIndex(index);
    };

    const getConvertedImageUrl = (originalUrl, size) => {
        const pathParts = originalUrl.split('/');
        const filename = pathParts[pathParts.length - 1];
        const basePath = pathParts.slice(0, -1).join('/');
        const baseFilename = filename.substring(0, filename.lastIndexOf('.'));
        const extension = filename.split('.').pop();
        return `${basePath}/conversions/${baseFilename}-${size}.${extension}`;
    };

    const centerThumbnail = (index) => {
        if (thumbnailRef.current) {
            const selectedThumbnail = thumbnailRef.current.children[index];
            const scrollLeft = selectedThumbnail.offsetLeft + selectedThumbnail.clientWidth / 2 - thumbnailRef.current.clientWidth / 2;
            thumbnailRef.current.scroll({ left: scrollLeft, behavior: 'smooth' });
        }
    };

    return (
        <div className='fixed inset-0 bg-zinc-900 flex flex-col items-center justify-center z-50 overflow-y-auto' {...swipeHandlers}>
            {flattenedImages && flattenedImages.length > 0 && (
                <>
                    <div className='min-h-[80vh] w-full flex items-center justify-center p-4'>
                        <img
                            src={flattenedImages[currentIndex].original_url}
                            className='object-contain h-full max-w-full'
                            alt='Gallery Image'
                        />
                    </div>
                    <div className='flex justify-between w-full max-w-full'>
                        <button onClick={goToPrevious} className='bg-white bg-opacity-70 px-8 py-2 rounded-md font-semibold'>
                            &#8592;
                        </button>
                        <button onClick={downloadImage} className='bg-white bg-opacity-70 px-4 py-2 rounded-md font-semibold'>
                            Download
                        </button>
                        <button onClick={goToNext} className='bg-white bg-opacity-70 px-8 py-2 rounded-md font-semibold'>
                            &#8594;
                        </button>
                    </div>
                    <div className='w-full overflow-x-auto flex flex-nowrap py-8 overflow-y-hidden' ref={thumbnailRef}>
                        {flattenedImages.map((img, index) => (
                            <img
                                key={index}
                                src={getConvertedImageUrl(img.original_url, 'small')}
                                className={`inline object-cover w-20 h-20 m-1 rounded-md cursor-pointer ${currentIndex === index ? 'border-2 border-white' : ''}`}
                                onClick={() => changeImage(index)}
                                alt={`Thumbnail ${index}`} alt={`Thumbnail ${index}`}
                            />
                        ))}
                    </div>
                    <button onClick={handleCarouselVisibility} className='text-white text-2xl absolute top-4 right-4'>
                        <XIcon className='w-8 h-8 rounded-full bg-zinc-800 text-white' />
                    </button>
                </>
            )}
        </div>
    );
};

export default MyImageCarousel;
