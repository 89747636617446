import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  HomeIcon,
  BuildingLibraryIcon,
  RectangleStackIcon,
  XMarkIcon,
  PhotoIcon,
  Cog6ToothIcon,
  QuestionMarkCircleIcon,
  StopCircleIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/24/outline";
import logo from "../Assets/logo.png";
import UserSettings from "../Pages/Private/Settings/UserSettings";
import SendFeedbackModal from "./SendFeedbackModal";
import { getCredits, logout } from "../Requests/User/UserRequests";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { useCredits } from "../utils/creditsManage";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
function SideBar() {
  const { credits, setCreditsValue } = useCredits();

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [viewFeedback, setViewFeedback] = useState(null);
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const cookies = new Cookies();
  const navigation = [
    { name: "Imagens", href: "/dashboard", icon: PhotoIcon },
    { name: "Créditos", href: "/subscription", icon: CurrencyDollarIcon },
    { name: "Definições", href: "/account_settings", icon: Cog6ToothIcon },
    { name: "Feedback", href: "#", icon: QuestionMarkCircleIcon },
  ].map(item => ({
    ...item,
    current: location.pathname === item.href
  }));

  useEffect(() => {
    getCredits().then((res) => {
      console.log(res.data.credits)
      setCreditsValue(res.data.credits)
    })
  }, [])

  const showFeedback = () => {
    setViewFeedback(<SendFeedbackModal key={Math.random()} />)
  }

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-zinc-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-gray-800">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4 bg-gradient-to-br from-lime-600 via-lime-700 to-green-800">
                  <div className="flex flex-shrink-0 items-center px-4">
                    <div className="w-full flex justify-center">
                      <img
                        className="h-24 w-auto rounded-xl"
                        src={logo}
                        alt="Your Company"
                      />
                    </div>
                  </div>

                  <nav className="mt-5 space-y-1 px-2">
                    {navigation.map(function (item) {
                      return item.name == "Feedback" ?
                        (<a
                          key={item.name}
                          onClick={showFeedback}
                          className={classNames(
                            item.current
                              ? "bg-lime-900 text-white"
                              : "text-gray-300 hover:bg-gray-700 hover:text-white",
                            "group flex items-center px-2 py-2 text-sm rounded-md text-white font-bold hover:bg-blue-800"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current
                                ? "text-white"
                                : "text-white group-hover:text-gray-300",
                              "mr-3 flex-shrink-0 h-6 w-6"
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>)
                        :
                        (<a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "bg-lime-900 text-white"
                              : " hover:bg-gray-700 hover:text-white",
                            "group flex items-center px-2 py-2 text-sm rounded-md text-white font-bold hover:bg-lime-800"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current
                                ? "text-white"
                                : "text-white group-hover:text-gray-300",
                              "mr-3 flex-shrink-0 h-6 w-6"
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>)
                    }
                    )}
                  </nav>
                </div>
                <div className="flex flex-shrink-0 bg-blue-900 p-4">
                  <a
                    onClick={() => {
                      logout()
                    }}
                    href="/login"
                    className="group block w-full flex-shrink-0"
                  >
                    <div className="flex items-center">
                      <div></div>
                      <div className="ml-3">
                        <p className="text-xs font-medium text-white group-hover:text-gray-200">
                          {t('Sidebar.LogoutText')}
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col ">
        <div className="flex min-h-0 flex-1 flex-col bg-gradient-to-b from-lime-600 via-lime-700 to-blue-800">
          <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
            <div className="flex flex-shrink-0 items-center">
              <div className="w-full flex justify-center">
                <img className="h-24 w-auto rounded-xl" src={logo} alt="Your Company" />
              </div>
            </div>

            <nav className="mt-5 flex-1 space-y-1 px-2 ">
              {navigation.map(function (item) {
                return item.name == "Feedback" ?
                  (<a
                    key={item.name}
                    onClick={showFeedback}
                    className={classNames(
                      item.current
                        ? "bg-gray-200 text-white font-bold"
                        : " hover:bg-gray-700 hover:text-white text-white",
                      "group flex items-center px-2 py-2 text-sm font-medium rounded-md font-bold hover:bg-lime-800"
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? "white font-bold"
                          : " group-hover:text-gray-300 ",
                        "mr-3 flex-shrink-0 h-6 w-6 font-bold text-white"
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </a>)
                  :
                  (<a
                    key={item.name}
                    href={item.href}
                    className={classNames(
                      item.current
                        ? "bg-lime-900 text-white"
                        : "text-white hover:bg-lime-800 hover:text-white",
                      "group flex items-center px-2 py-2 text-sm  rounded-md font-bold text-white"
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? ""
                          : " group-hover:text-gray-300 ",
                        "mr-3 flex-shrink-0 h-6 w-6 text-white "
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </a>)
              })}
            </nav>
          </div>
          <div className="flex w-full justify-center items-center text-sm">
            <div className="flex  rounded-full w-full text-white px-2 py-2 m-2 gap-2 bg-zinc-900 ">
              <div className="flex px-4 gap-1 justify-center items-center">
                {credits}
                <StopCircleIcon className="w-6 h-6 text-white"></StopCircleIcon>
              </div>
              <button onClick={() => { navigate("/subscription") }} className="bg-gradient-to-br from-blue-800 via-lime-700 to-lime-600 rounded-full px-4">Obter créditos</button>
            </div>
          </div>
          <div className="flex flex-shrink-0 bg-blue-900 p-4">
            <a
              onClick={() => {
                logout()
              }}
              href="/login"
              className="group block w-full flex-shrink-0"
            >
              <div className="flex items-center">
                <div></div>
                <div className="ml-3">
                  <p className="text-xs font-medium text-white group-hover:text-gray-200">
                    {t('Sidebar.LogoutText')}
                  </p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="sticky top-0 z-10 bg-zinc-800 pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden flex justify-between">

        <button
          type="button"
          className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-lime-500"
          onClick={() => setSidebarOpen(true)}
        >

          <Bars3Icon className="h-6 w-6" aria-hidden="true" />

        </button>
        <div className="flex w-full justify-end items-center text-sm">
          <div className="flex  rounded-full w-fit text-white px-2 py-2 m-2 gap-0 bg-zinc-900 ">
            <div className="flex px-4 gap-1 justify-center items-center">
              {credits}
              <StopCircleIcon className="w-6 h-6 text-white"></StopCircleIcon>
            </div>
            <button onClick={() => { navigate("/subscription") }} className="bg-gradient-to-br from-lime-600 via-lime-700 to-green-800 rounded-full px-4">Obter créditos</button>
          </div>
        </div>
      </div>
      {viewFeedback}
    </div>
  );
}

export default SideBar;
