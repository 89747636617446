import React, { useEffect, useState } from 'react';
import { generationStatus, getImages } from '../Requests/ImageRequests';
import { useCredits } from '../utils/creditsManage';
import { getCredits } from '../Requests/User/UserRequests';
import { ClipboardCopyIcon } from 'lucide-react';

import MyImageCarousel from "./MyImageCarousel";

function MyImageGallery({ creatingImages, setCreatingImages, createImagesError, setCreateImagesError, setPrompt }) {
    const [generatedImages, setGeneratedImages] = useState(null);
    const [showCarousel, setShowCarousel] = useState(false);
    const [imageIndex, setImageIndex] = useState(0);
    const [intervalId, setIntervalId] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [pagination, setPagination] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { credits, setCreditsValue } = useCredits();

    const loadImages = async (page) => {
        try {
            setIsLoading(true);
            const res = await getImages(page);
            setGeneratedImages(res.data.data);
            setPagination({
                current_page: res.data.current_page,
                last_page: res.data.last_page,
                per_page: res.data.per_page,
                total: res.data.total
            });
            setIsLoading(false);
        } catch (error) {
            console.error('Error loading images:', error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (!creatingImages) {
            loadImages(currentPage);
            generationStatus()
                .then(response => {
                    if (response.data.status === 'Generating') {
                        setCreatingImages(true);
                    }
                });
            getCredits().then((res) => {
                setCreditsValue(res.data.credits);
            });
        }
    }, [creatingImages, currentPage]);

    useEffect(() => {
        if (creatingImages) {
            const checkStatus = () => {
                generationStatus()
                    .then(response => {
                        if (response.data.status === 'Done') {
                            clearInterval(intervalId);
                            setCreatingImages(false);
                            loadImages(1);
                            setCurrentPage(1);
                        }
                        if (response.data.status === 'Expired' || response.data.status === 'Failed') {
                            clearInterval(intervalId);
                            setCreatingImages(false);
                            setCreateImagesError(true);
                        }
                    })
                    .catch(error => {
                        console.error('Error checking generation status:', error);
                        clearInterval(intervalId);
                        setCreatingImages(false);
                    });
            };

            const initialTimeoutId = setTimeout(() => {
                checkStatus();
                const newIntervalId = setInterval(checkStatus, 10000);
                setIntervalId(newIntervalId);
            }, 5000);

            return () => {
                clearTimeout(initialTimeoutId);
                clearInterval(intervalId);
            };
        }
    }, [creatingImages]);

    const getFlattenedIndex = (groupIndex, imageIndex) => {
        let flattenedIndex = 0;
        for (let i = 0; i < groupIndex; i++) {
            if (generatedImages[i].job_status !== "Failed") {
                flattenedIndex += generatedImages[i].media.length;
            }
        }
        return flattenedIndex + imageIndex;
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        window.scrollTo(0, 0);
    };

    const handleCarouselVisibility = () => {
        setShowCarousel(!showCarousel);
    };

    const handleImageClick = (groupIndex, imageIndex) => {
        const flattenedIndex = getFlattenedIndex(groupIndex, imageIndex);
        setImageIndex(flattenedIndex);
        handleCarouselVisibility();
    };

    const getConvertedImageUrl = (originalUrl, size) => {
        const pathParts = originalUrl.split('/');
        const filename = pathParts[pathParts.length - 1];
        const basePath = pathParts.slice(0, -1).join('/');
        const baseFilename = filename.substring(0, filename.lastIndexOf('.'));
        const extension = filename.split('.').pop();
        return `${basePath}/conversions/${baseFilename}-${size}.${extension}`;
    };

    const handleCopyDescription = (description) => {
        setPrompt(description);
        window.scrollTo(0, 0);
    };

    const renderPaginationButtons = () => {
        if (!pagination) return null;

        const buttons = [];
        const maxVisiblePages = 5;
        let startPage = Math.max(1, currentPage - 2);
        let endPage = Math.min(pagination.last_page, startPage + maxVisiblePages - 1);

        if (endPage - startPage < maxVisiblePages - 1) {
            startPage = Math.max(1, endPage - maxVisiblePages + 1);
        }

        buttons.push(
            <button
                key="prev"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="px-3 py-1 rounded-md bg-zinc-900 text-white"
            >
                ←
            </button>
        );

        if (startPage > 1) {
            buttons.push(
                <button
                    key={1}
                    onClick={() => handlePageChange(1)}
                    className="px-3 py-1 rounded-md hover:bg-gray-200 bg-zinc-900 text-white"
                >
                    1
                </button>
            );
            if (startPage > 2) buttons.push(<span key="dots1">...</span>);
        }

        for (let i = startPage; i <= endPage; i++) {
            buttons.push(
                <button
                    key={i}
                    onClick={() => handlePageChange(i)}
                    className={`px-3 py-1 rounded-md ${currentPage === i
                        ? 'bg-lime-600 text-white'
                        : 'hover:bg-gray-200 bg-zinc-900 text-white'
                        }`}
                >
                    {i}
                </button>
            );
        }

        if (endPage < pagination.last_page) {
            if (endPage < pagination.last_page - 1) buttons.push(<span key="dots2">...</span>);
            buttons.push(
                <button
                    key={pagination.last_page}
                    onClick={() => handlePageChange(pagination.last_page)}
                    className="px-3 py-1 rounded-md bg-zinc-900 text-white"
                >
                    {pagination.last_page}
                </button>
            );
        }

        buttons.push(
            <button
                key="next"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === pagination.last_page}
                className="px-3 py-1 rounded-md bg-zinc-900 text-white"
            >
                →
            </button>
        );

        return buttons;
    };

    return (
        <div className='w-full'>
            {createImagesError && (
                <p className='text-red-600 text-sm'>
                    Ocorreu um erro a criar as imagens. Verifique que não está a utilizar termos incomuns e volte a tentar.
                </p>
            )}

            {(!creatingImages && !generatedImages?.length > 0) &&
                <div className='flex flex-col gap-2 items-center'>
                    <img src='https://imagemaispace.fra1.digitaloceanspaces.com/108/e799e637-8c36-4469-a34a-21cd95e7b3f9.png'
                        className='aspect-square object-cover max-w-sm rounded-lg'
                    ></img>
                    <div className='flex flex-col gap-1 items-center'>
                        <div className='text-white text-center text-lg font-bold'>
                            Ainda não tem imagens!
                        </div>
                        <div className='text-white text-center text-lg '>
                            Escreva o que quer criar para começar
                        </div>
                    </div>
                </div>
            }

            {creatingImages && (
                <div className='flex flex-wrap justify-center items-center w-full'>
                    {[...Array(4)].map((_, i) => (
                        <div key={i} className='w-1/2 lg:w-1/4 aspect-square flex justify-center items-center p-1'>
                            <div className='animate-pulse bg-zinc-600 w-full h-full rounded-md flex justify-center items-center text-white text-lg'>
                                A criar imagem...
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {generatedImages?.map((images, groupIndex) => (
                <div key={groupIndex}>
                    <div className='flex items-center gap-2'>
                        <div className='text-white pt-4 pb-2'>{images?.image_description.split(" --ar")[0]}</div>
                        <button
                            onClick={() => handleCopyDescription(images?.image_description.split(" --ar")[0])}
                            className="bg-zinc-900 text-white px-2 py-1 rounded-md hover:bg-zinc-800 mt-2"
                        >
                            <ClipboardCopyIcon className='w-4' />
                        </button>
                    </div>
                    <div className='flex flex-wrap justify-center items-center max-w-full'>
                        {images.job_status === "Failed" ? (
                            Array(4).fill(null).map((_, index) => (
                                <div key={index} className='w-1/2 p-1 lg:w-1/4'>
                                    <div className='w-full h-full aspect-square bg-zinc-900 rounded-md flex items-center justify-center text-red-800 font-bold p-4 text-center'>
                                        Não foi possivel gerar a imagem
                                    </div>
                                </div>
                            ))
                        ) : (
                            images.media.map((img, index) => (
                                <div
                                    key={index}
                                    className='w-1/2 p-1 lg:w-1/4 cursor-pointer'
                                    onClick={() => handleImageClick(groupIndex, index)}
                                >
                                    <img
                                        src={getConvertedImageUrl(img.original_url, 'medium')}
                                        className='w-full object-contain rounded-md'
                                        alt={`Generated image ${index + 1}`}
                                    />
                                </div>
                            ))
                        )}
                    </div>
                </div>
            ))}

            {pagination && pagination.last_page > 1 && (
                <div className="flex justify-center gap-2 my-6">
                    {renderPaginationButtons()}
                </div>
            )}

            {generatedImages && showCarousel && (
                <MyImageCarousel
                    generatedImages={generatedImages.filter(img => img.job_status !== "Failed")}
                    handleCarouselVisibility={handleCarouselVisibility}
                    imageIndex={imageIndex}
                />
            )}
        </div>
    );
}

export default MyImageGallery;
