import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  createNewSubscription,
  getAvailablePlans,
} from "../../../../Requests/User/UserRequests";
import { CheckIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { sendAmplitudeData } from "../../../../utils/amplitude";
import Loader from "../../../../Components/Loader";
const features = ["500+ items", "Unlimited Palaces"];

function ViewPlans() {
  const { t } = useTranslation();
  const [plans, setPlans] = useState(null);
  const [planError, setPlanError] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const getPlans = async () => {
    let response = getAvailablePlans();
    response
      .then((res) => {
        console.log(res);
        if (res.data) {
          setPlans(res.data);
        }
      })
      .catch((error) => {
        console.log("There was an error at login", error.message);
      });
  };

  function createSubscription(plan_id) {
    setIsLoading(true)
    let response = createNewSubscription(plan_id);
    response
      .then((res) => {
        setIsLoading(false)
        if (res.data.status == "failed") {
          setPlanError(true);
        } else if (res.data) {
          navigate(
            "/payment_option_details?payment_intent_client_secret=" +
            res.data.clientSecret,
            { replace: true }
          );
          window.location.reload();
        }
      })
      .catch((error) => {
        setIsLoading(false)
        console.log("There was an error at login", error.message);
      });
  }

  useEffect(() => {
    getPlans();
    sendAmplitudeData({ "type": "Navigation", "value": "Pricing Plans" })
  }, []);

  return (
    <div>
      <h1 className="text-4xl font-semibold text-gray-900 my-8 mx-8 text-left">
        {t("Billing.SeePlansTabTitle")}
      </h1>
      <h1 className="text-xl  text-gray-900 my-8 mx-8 text-left">
        {t("Billing.SeePlansTabDescription")}
      </h1>
      {plans ? (
        <div className="mx-auto max-w-md space-y-4 lg:grid lg:max-w-5xl lg:grid-cols-2 lg:gap-5 lg:space-y-0 mb-20">
          {plans.data.map((plan) => {
            return (
              <div
                key={plan.name}
                className="flex flex-col overflow-hidden rounded-lg shadow-lg"
              >
                <div className="bg-white px-6 py-8 sm:p-10 sm:pb-6">
                  <div>
                    <h3
                      className="inline-flex rounded-full bg-lime-100 px-4 py-1 text-base font-semibold text-lime-600"
                      id="tier-standard"
                    >
                      {plan.nickname}
                    </h3>
                  </div>
                  <div className="mt-4 flex items-baseline text-6xl font-bold tracking-tight">
                    ${plan.amount / 100}
                  </div>
                </div>
                <div className="flex flex-1 flex-col justify-between space-y-6 bg-gray-50 px-6 pt-6 pb-8 sm:p-10 sm:pt-6">
                  <ul role="list" className="space-y-4">
                    {features.map((feature) => (
                      <li key={feature} className="flex items-start">
                        <div className="flex-shrink-0">
                          <CheckIcon
                            className="h-6 w-6 text-green-500"
                            aria-hidden="true"
                          />
                        </div>
                        <p className="ml-3 text-base text-gray-700">
                          {feature}
                        </p>
                      </li>
                    ))}
                  </ul>
                  <div className="rounded-md shadow">
                    <button
                      onClick={() => isLoading ? null : createSubscription(plan.id)}
                      key={plan.id}
                      className="w-full flex items-center justify-center rounded-md border border-transparent bg-gray-800 px-5 py-3 text-base font-medium text-white hover:bg-gray-900"
                      aria-describedby="tier-standard"
                    >
                      {isLoading ? <Loader /> : t("Billing.SeePlansCallToAction")}
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <></>
      )}
      {planError && (
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg
                className="h-5 w-5 text-red-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium text-red-800">
                {t("Billing.UserAlreadyPremiumWarning")}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ViewPlans;
