import { Bars3Icon, ChevronRightIcon, XMarkIcon } from '@heroicons/react/20/solid'
import bgImage from '../../Assets/bg-hero.png'
import logo from '../../Assets/logo.png'
import { Dialog } from '@headlessui/react'
import { useState } from 'react'
import cat from '../../Assets/cat.png'
const navigation = [

]

export default function HeroSection() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    return (
        <div className="relative isolate overflow-hidden w-screen h-fit pb-12 justify-center" style={{ background: `url(${cat})`, backgroundAttachment: 'fixed', backgroundSize: 'cover', backgroundPosition: 'center center' }}>
            <div className="absolute inset-0 bg-gradient-to-b from-transparent from-0% via-transparent via-80% to-black to-90%"></div> {/* Overlay with zinc-900 color */}

            <header className="absolute inset-x-0 top-0 z-50">
                <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
                    <div className="flex lg:flex-1">
                        <a href="#" className="-m-1.5 p-1.5">
                            <img className="h-16 w-auto" src={logo} alt="" />
                        </a>
                        <div className='flex items-center'>
                            <div className="ml-4 text-lg font-semibold leading-6 text-white">ImagemIA</div>
                        </div>
                    </div>

                    <div className="flex lg:flex-1 lg:justify-end">
                        <a href="/login" className="text-xl font-bold leading-6 text-white">
                            Login <span aria-hidden="true">&rarr;</span>
                        </a>
                    </div>
                </nav>
            </header>

            <div className='flex w-full flex-col justify-end text-center relative h-screen pb-12 md:pb-36'>
                <div className='relative z-10'>
                    <h1 className='text-white text-4xl md:text-7xl font-bold'>Crie imagens com o poder da</h1>
                    <h1 className='text-white text-4xl md:text-7xl font-bold'>
                        <span className="text-4xl md:text-7xl font-bold inline-block text-transparent bg-clip-text bg-gradient-to-r from-lime-600 via-lime-500 to-blue-700 px-4 pb-2 ">Inteligência Artificial </span>
                    </h1>
                    <div className='flex w-full justify-center items-center mt-2 px-8'>
                        <div className="mt-8 flex flex-col sm:flex-row w-full justify-center items-center gap-4 flex-1">
                            <input
                                id="prompt"
                                name="prompt"
                                type="text"
                                required
                                className="block w-full max-w-2xl appearance-none rounded-md border border-gray-300 px-4 py-3 placeholder-gray-400 shadow-sm focus:border-lime-500 focus:outline-none focus:ring-lime-500 sm:text-sm"
                            />
                            <a
                                href='/register'
                                type="submit"
                                className="w-full sm:w-fit text-xl flex-none rounded-md px-3.5 py-2.5 font-semibold text-white shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white bg-gradient-to-br from-lime-500 via-lime-700 to-blue-800 hover:from-lime-400 hover:via-lime-700 hover:to-lime-700"
                            >
                                Criar imagem
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}
