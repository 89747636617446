import React from 'react';
import { X } from 'lucide-react';

const NoCreditsModal = ({ isOpen, onClose, onViewPlans }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center p-4 z-50">
            <div className="bg-zinc-900 rounded-lg shadow-xl max-w-xl w-full relative">
                {/* Close button */}
                <button
                    onClick={onClose}
                    className="absolute right-4 top-4 text-gray-500 hover:text-gray-700"
                >
                    <X size={24} />
                </button>

                {/* Image */}
                <div className="w-full h-64  rounded-t-lg flex items-center justify-center">
                    <img
                        src="https://imagemaispace.fra1.digitaloceanspaces.com/56/5504ef5c-76fe-44da-8977-5bac02106e0e.png"
                        alt="Empty credits illustration"
                        className="w-full h-64 object-cover object-top"
                    />
                </div>

                {/* Content */}
                <div className="p-6 text-center">
                    <h3 className="text-xl font-bold text-white mb-4">
                        Não tem créditos
                    </h3>
                    <p className="text-gray-300 mb-6">
                        Subscreva para obter mais créditos
                    </p>
                    <button
                        onClick={onViewPlans}
                        className="w-full text-white text-lg font-bold bg-gradient-to-br from-blue-800 via-lime-700 to-lime-600 rounded-md px-4 py-2"
                    >
                        Ver planos
                    </button>
                </div>
            </div>
        </div>
    );
};

export default NoCreditsModal;
