import React from 'react'

function Loader({ w = 4, h = 4 }) {
    return (
        <div className="flex justify-center items-center">
            <div
                className={"spinner-border animate-spin inline-block border-4 rounded-full w-" + w + " h-" + h}
                role="status"
            >
                <span className="visually-hidden"></span>
            </div>
        </div>
    )
}

export default Loader
