
import logo from "./logo.svg";
import "./App.css";
import Login from "./Pages/Public/Login";
import Register from "./Pages/Public/Register";
import Cookies from "universal-cookie";
import { BrowserRouter, Routes, Navigate, Route } from "react-router-dom";
import Dashboard from "./Pages/Private/Dashboard";
import Landing from "./Pages/Public/Landing";
import ChoosePlans from "./Pages/Private/Settings/Payments/ChoosePlans";
import ViewPlans from "./Pages/Private/Settings/Payments/ViewPlans";
import SideBar from "./Components/SideBar";
import UserSettings from "./Pages/Private/Settings/UserSettings";
import BillingController from "./Pages/Private/Settings/Payments/BillingController";
import ViewSubscriptions from "./Pages/Private/Settings/Payments/ViewSubscriptions";
import EmailConfirmation from "./Pages/Public/EmailConfirmation";
import RequestPasswordChange from "./Pages/Public/RequestPasswordChange";
import PasswordChange from "./Pages/Public/PasswordChange";
import CookieConsent from "react-cookie-consent";
import AccountSettings from "./Pages/Private/Settings/Account/AccountSettings";
import NotFound from "./Pages/Public/NotFound";
import SubscriptionPlans from "./Pages/Private/Settings/Payments/PaddleSubscription";
import { CreditsProvider } from "./utils/creditsManage";
import StripeSubscription from "./Pages/Private/Settings/Payments/StripeSubscription";
import { useEffect } from "react";
import { clarity } from 'react-microsoft-clarity';
import { initFacebookPixel, useFacebookPixel } from "./utils/facebookpixel";
function PrivateOutlet({ children }) {
  const cookies = new Cookies();
  const token = cookies.get("token");
  return !!token ? children : <Navigate to="/login" />;
}

function PublicOutlet({ children }) {
  const cookies = new Cookies();
  const token = cookies.get("token");
  return !!token ? <Navigate to="/dashboard" /> : children;
}

function CookieWarning() {
  const cookies = new Cookies();
  const cookieConsent = cookies.get("cookieConsent");
  if (!cookieConsent) {
    return <CookieConsent
      location="bottom"
      buttonText="Accept"
      cookieName="Consent_cookie"
      style={{ background: "#2B373B" }}
      buttonStyle={{ background: "#4f46e5", color: "white", fontSize: "13px" }}
    >
      Este website usa cookies para melhor a sua experiencia.{" "}
    </CookieConsent>
  }
}

function App() {
  const cookies = new Cookies();
  const token = cookies.get("token");

  useEffect(() => {
    const cookies = new Cookies();
    clarity.init(process.env.REACT_APP_CLARITY_KEY);
    clarity.identify('USER_ID', { userProperty: 'value' });
    clarity.consent();
    clarity.setTag('key', 'value');
    clarity.upgrade('upgradeReason');
    if (clarity.hasStarted()) {
      clarity.identify(token, { userProperty: 'value' });
    }

  }, [])

  useEffect(() => {
    // Initialize Facebook Pixel
    initFacebookPixel();

    // Your existing useEffect code
    const cookies = new Cookies();
    clarity.init(process.env.REACT_APP_CLARITY_KEY);
    clarity.identify('USER_ID', { userProperty: 'value' });
    clarity.consent();
    clarity.setTag('key', 'value');
    clarity.upgrade('upgradeReason');
    if (clarity.hasStarted()) {
      clarity.identify(token, { userProperty: 'value' });
    }
  }, []);

  // Create a wrapper component to use the Facebook Pixel hook
  function PixelTracker() {
    useFacebookPixel();
    return null;
  }

  return (
    <BrowserRouter>
      <CreditsProvider>
        <PixelTracker />
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/login" element={<PublicOutlet><Login /></PublicOutlet>} />
          <Route path="/register" element={<PublicOutlet><Register /></PublicOutlet>} />
          <Route path="/email_confirmation" element={<EmailConfirmation />} />
          <Route path="/request_password_change" element={<RequestPasswordChange />} />
          <Route path="/change_password" element={<PasswordChange />} />
          <Route
            path="/dashboard"
            element={
              <PrivateOutlet>
                <SideBar />
                <Dashboard />
              </PrivateOutlet>
            }
          />
          {/*Settings */}
          {/*   Account */}
          <Route
            path="/account_settings"
            element={
              <PrivateOutlet>
                <SideBar />
                <AccountSettings />
              </PrivateOutlet>
            }
          />
          {/*   Billing*/}
          <Route
            path="/subscription"
            element={
              <PrivateOutlet>
                <SideBar />
                <StripeSubscription />
              </PrivateOutlet>
            }
          />
          <Route
            path="/subscription&checkout=success"
            element={
              <PrivateOutlet>
                <SideBar />
                <StripeSubscription />
              </PrivateOutlet>
            }
          />
          <Route
            path="/empty_subscriptions"
            element={
              <PrivateOutlet>
                <SideBar />
                <UserSettings tabName="Plans">
                  <BillingController />
                </UserSettings>
              </PrivateOutlet>
            }
          />
          <Route
            path="/view_subscriptions"
            element={
              <PrivateOutlet>
                <SideBar />
                <UserSettings tabName="Plans">
                  <BillingController>
                    <ViewSubscriptions />
                  </BillingController>
                </UserSettings>
              </PrivateOutlet>
            }
          />
          <Route
            path="/plans"
            element={
              <PrivateOutlet>
                <SideBar />
                <UserSettings tabName="Plans">
                  <BillingController>
                    <ViewPlans />
                  </BillingController>
                </UserSettings>
              </PrivateOutlet>
            }
          />
          <Route
            path="/payment_option_details"
            element={
              <PrivateOutlet>
                <SideBar />
                <UserSettings tabName="Plans">
                  <BillingController forceRefresh={true}>
                    <ChoosePlans />
                  </BillingController>
                </UserSettings>
              </PrivateOutlet>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </CreditsProvider>
    </BrowserRouter>
  );
}
//<BrowserRouter basename={window.location.pathname || ''}>
//<Route exact path="/login" element={Boolean(token)?<Login/>:<Navigate to="/register" replace/>}/>
export default App;
