import Cookies from "universal-cookie";
import axios from "axios";
import { data } from "autoprefixer";

function getToken() {
    const cookies = new Cookies();
    return cookies.get("token");
}

export function removeToken() {
    const cookies = new Cookies();
    return cookies.remove("token");
}

export function getImages(page = 1) {
    var url = process.env.REACT_APP_API_BASE_URL + `/images?page=${page}`;
    return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } });
}


export function generateImage(data) {
    var url = process.env.REACT_APP_API_BASE_URL + `/image_generation`;
    return axios.post(url, data, { headers: { authorization: `Bearer ${getToken()}` } });
}


export function generationStatus() {
    var url = process.env.REACT_APP_API_BASE_URL + `/check_generation_status`;
    return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } });
}
