// src/services/requests.js
import Cookies from "universal-cookie";
import axios from "axios";

function getToken() {
    const cookies = new Cookies();
    return cookies.get("token");
}

// Subscription related requests
export function getSubscriptionPlans() {
    var url = process.env.REACT_APP_API_BASE_URL + `/subscription/plans`;
    return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function getCurrentSubscription() {
    var url = process.env.REACT_APP_API_BASE_URL + `/subscription/current`;
    return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function cancelSubscription() {
    var url = process.env.REACT_APP_API_BASE_URL + `/subscription/cancel`;
    return axios.post(url, {}, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function resumeSubscription() {
    var url = process.env.REACT_APP_API_BASE_URL + `/subscription/resume`;
    return axios.post(url, {}, { headers: { authorization: `Bearer ${getToken()}` } });
}

// Credits related requests
export function getCreditsBalance() {
    var url = process.env.REACT_APP_API_BASE_URL + `/credits/balance`;
    return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function getCreditHistory() {
    var url = process.env.REACT_APP_API_BASE_URL + `/credits/history`;
    return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function calculateCreditPrice(quantity) {
    var url = process.env.REACT_APP_API_BASE_URL + `/credits/calculate-price`;
    return axios.post(url, { quantity }, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function purchaseCredits(quantity) {
    var url = process.env.REACT_APP_API_BASE_URL + `/credits/purchase`;
    return axios.post(url, {
        items: [{
            priceId: process.env.REACT_APP_PADDLE_CREDIT_PRICE_ID,
            quantity: quantity
        }]
    }, {
        headers: { authorization: `Bearer ${getToken()}` }
    });
}

export const createCheckoutSession = (data) => {
    var url = process.env.REACT_APP_API_BASE_URL + '/subscription/checkout';
    return axios.post(url, data, {
        headers: { authorization: `Bearer ${getToken()}` }
    });
};
