import React from 'react'
import Faqs from '../../Components/LandingComponents/Faqs'
import HeroSection from '../../Components/LandingComponents/HeroSection';
import Gallery from '../../Components/LandingComponents/Galery';
import Features from '../../Components/LandingComponents/Features';
import PricingSection from '../../Components/LandingComponents/PricingSection';

function Landing() {

    return (
        <div>
            <HeroSection />
            <Gallery />
            <Features />
            <PricingSection />
            {/*<PricingSection />
            <Faqs />*/}
            <footer className="bg-black text-white">
                <div className="mx-auto max-w-7xl px-6 py-12 md:flex md:items-center md:justify-between lg:px-8">
                    <div className="flex justify-center gap-x-6 ">

                        <p className="mt-8 text-center text-sm/6 text-white md:order-1 md:mt-0">
                            &copy; 2024 um produto de Eclipsingredients. Todos os direitos reservados.
                        </p>
                    </div>
                    <div className='flex flex-col md:flex-row gap-4 text-sm'>
                        <a
                            href='https://imagemaispace.fra1.digitaloceanspaces.com/Files/Politica%20de%20provacidade.pdf'
                            download="politica-de-privacidade.pdf"
                            rel="noopener noreferrer"
                            target='_blank'
                        >
                            Política de privacidade
                        </a>
                        <a
                            href='https://imagemaispace.fra1.digitaloceanspaces.com/Files/Termos%20e%20Condic%CC%A7o%CC%83es%20de%20Uso.pdf'
                            download="termos-e-condicoes.pdf"
                            rel="noopener noreferrer"
                            target='_blank'
                        >
                            Termos e condições
                        </a>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Landing
