import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { register } from "../../Requests/User/UserRequests";
import register_brain from "../../Assets/cat.png";
import logo from "../../Assets/logo.png";
import SuccesModal from "../../Components/SuccesModal";
import ErrorModal from "../../Components/ErrorModal";
import { useTranslation } from "react-i18next";
import Loader from "../../Components/Loader";
import { sendAmplitudeData } from "../../utils/amplitude";
import { trackRegistration } from "../../utils/facebookpixel";
import PasswordInput from "../../Components/RegisterComponents/PasswordInput";
import Cookies from "universal-cookie";

function Register() {
  const [goToLogin, setGoToLogin] = useState(false);
  const [feedbackModal, setFeedbackModal] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  let handleSubmit = (event) => {
    setFeedbackModal(false)
    setIsLoading(true);
    event.preventDefault();
    let response = register(
      event.target.name.value,
      event.target.email.value,
      event.target.password.value,
      event.target.password_confirmation.value
    );
    response.then((res) => {
      setIsLoading(false);
      if (res.data.token) {
        const cookies = new Cookies();
        cookies.set("token", res.data.token);
        cookies.set("user_id", res.data.user_id);
        cookies.set("analitics_token", res.data.user_id);

        setFeedbackModal(
          <SuccesModal
            title={t('RegisterPage.SuccessModalTitle')}
            body={t('RegisterPage.SuccessModalText')}
            continue={t('RegisterPage.SuccessModalButton')}
            navLink="/dashboard"
          />
        );
      }
      trackRegistration({
        user_type: 'new',
        method: 'email'
      });
    })
      .catch((error) => {
        setIsLoading(false)
        error?.response?.data?.errors?.email ? (
          setFeedbackModal(
            <ErrorModal
              title={t('RegisterPage.FailModalTitle')}
              body={error?.response?.data?.errors?.email}
              continue={t('RegisterPage.FailModalButton')}
            />
          )) : error?.response?.data?.errors?.password ? (
            setFeedbackModal(
              <ErrorModal
                title={t('RegisterPage.FailModalTitle')}
                body={error?.response?.data?.errors?.password}
                continue={t('RegisterPage.FailModalButton')}
              />
            )) : <ErrorModal
          title={t('RegisterPage.FailModalTitle')}
          body={t('RegisterPage.FailModalText')}
          continue={t('RegisterPage.FailModalButton')}
        />
      });
  };

  function redirectToLogin() {
    setGoToLogin(true);
  }

  useEffect(() => {
    if (goToLogin) {
      navigate(`/login`);
    }
    sendAmplitudeData({ "type": "Navigation", "value": "Register" })
  }, [goToLogin]);

  return (
    <>
      <div className="flex min-h-full">
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={register_brain}
            alt=""
          />
        </div>
        <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <div className="w-full flex justify-center">
                <img className="h-32 w-auto" src={logo} alt="Your Company" />
              </div>
              <h2 className="mt-6 text-3xl font-bold tracking-tight text-white">
                {t('RegisterPage.PageTitle')}
              </h2>
            </div>

            <div className="mt-8  ">
              <div className="mt-6  ">
                <form className="space-y-6 w-full" onSubmit={handleSubmit}>
                  <div >
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-white"
                    >
                      {t('RegisterPage.NameField')}
                    </label>
                    <div className="mt-1">
                      <input
                        id="name"
                        name="name"
                        type="text"
                        required
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-lime-500 focus:outline-none focus:ring-lime-500 sm:text-sm"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-white"
                    >
                      {t('RegisterPage.EmailField')}
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-lime-500 focus:outline-none focus:ring-lime-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <PasswordInput
                    id="password"
                    name="password"
                    label={t('RegisterPage.PasswordField')}
                  />
                  <PasswordInput
                    id="password_confirmation"
                    name="password_confirmation"
                    label={t('RegisterPage.ConfirmPasswordField')}
                  />

                  <div className="flex items-center justify-between">
                    <div className="text-sm">
                      <a
                        onClick={redirectToLogin}
                        className="font-medium text-lime-600 hover:text-lime-500"
                      >
                        {t('RegisterPage.LoginLink')}
                      </a>
                    </div>
                  </div>

                  <div><button
                    type="submit"
                    className="flex w-full justify-center rounded-md border border-transparent bg-lime-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-lime-500 focus:ring-offset-2"
                  >
                    {isLoading ? <Loader /> :
                      t('RegisterPage.SubmitButton')
                    }
                  </button>
                    <div className=' text-sm text-white '>

                      Ao clicar registar, você concorda e aceita a nossa

                      <span><a
                        href='https://imagemaispace.fra1.digitaloceanspaces.com/Files/Politica%20de%20provacidade.pdf'
                        download="politica-de-privacidade.pdf"
                        rel="noopener noreferrer"
                        target='_blank'
                        className="text-lime-600 underline mx-1"
                      >
                        Política de privacidade
                      </a>
                      </span>
                      e os nossos
                      <span>
                        <a
                          href='https://imagemaispace.fra1.digitaloceanspaces.com/Files/Termos%20e%20Condic%CC%A7o%CC%83es%20de%20Uso.pdf'
                          download="termos-e-condicoes.pdf"
                          rel="noopener noreferrer"
                          target='_blank'
                          className="text-lime-600 underline mx-1"
                        >
                          Termos e condições
                        </a>
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {feedbackModal}
      </div>
    </>
  );
}

export default Register;
