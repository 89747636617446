import React from 'react';
import { Link } from 'react-router-dom';

function PricingSection() {
    // Example plans - replace with your actual plans data
    const plans = [
        {
            id: 'basic',
            name: 'Plano Básico',
            price: 50,
            monthly_credits: 500,
            features: [
                '500 créditos mensais (aprox. 200 imagens)',
            ]
        },
        {
            id: 'advanced',
            name: 'Plano Avançado',
            price: 100,
            monthly_credits: 1000,
            features: [
                '1000 créditos mensais (aprox. 400 imagens)',
            ]
        },
        {
            id: 'pro',
            name: 'Plano Profissional',
            price: 300,
            monthly_credits: 3500,
            features: [
                '3500 créditos mensais (aprox. 1400 imagens)',
            ]
        }
    ];

    return (
        <div className="py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-4xl text-center">
                    <h2 className="text-base font-semibold leading-7 text-lime-600">Preços</h2>
                    <p className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
                        O plano certo para si
                    </p>
                </div>
                <div className="grid grid-cols-1 gap-6 md:grid-cols-3 mt-16 max-w-7xl mx-auto">
                    {plans.map((plan) => (
                        <div key={plan.name}
                            className="rounded-lg border border-gray-200 p-6 bg-zinc-900">
                            <h3 className="text-xl font-bold mb-2 text-white">{plan.name}</h3>
                            <p className="text-3xl font-bold mb-4 text-white">
                                R${plan.price}
                                <span className="text-sm text-gray-400">/mês</span>
                            </p>

                            <ul className="mb-6 space-y-2">
                                <li className="flex items-center text-white">
                                    <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                    </svg>
                                    {plan.monthly_credits} créditos (aprox. {Math.round(plan.monthly_credits / 10 * 4)} imagens)
                                </li>
                                {plan.credit_discount && (
                                    <li className="flex items-center text-white">
                                        <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                        </svg>
                                        {plan.credit_discount * 100}% desconto em créditos adicionais
                                    </li>
                                )}
                            </ul>

                            <Link
                                to="/register"
                                className="w-full py-2 px-4 rounded-lg bg-lime-600 hover:bg-lime-700 text-white text-center block"
                            >
                                Começar gratuitamente
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default PricingSection;
