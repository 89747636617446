import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { getCreditsBalance, getCurrentSubscription, getSubscriptionPlans, cancelSubscription, resumeSubscription, createCheckoutSession } from '../../../../Requests/Subscriptions';
import { getCredits } from '../../../../Requests/User/UserRequests';
import { useCredits } from '../../../../utils/creditsManage';
import { useLocation, useNavigate } from 'react-router-dom';
import SuccesModal from '../../../../Components/SuccesModal';


// Initialize Stripe
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export default function StripeSubscription() {
    const [plans, setPlans] = useState([]);
    const [currentSubscription, setCurrentSubscription] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const { credits, setCreditsValue } = useCredits();


    useEffect(() => {
        // Check URL parameters for successful checkout
        const urlParams = new URLSearchParams(location.search);
        const sessionId = urlParams.get('session_id');
        const successPlanId = urlParams.get('plan');

        const handleSuccessfulSubscription = async (planId) => {
            try {
                const res = await getCredits();
                setCreditsValue(res.data.credits);

                // Find the plan details from the plans array
                const subscribedPlan = plans.find(p => p.id === planId);

                // Track successful subscription with plan details
                if (window.fbq && subscribedPlan) {
                    window.fbq('track', 'Subscribe', {
                        currency: 'BRL',
                        value: subscribedPlan.price,
                        predicted_ltv: subscribedPlan.price * 12, // Yearly value
                        content_name: subscribedPlan.name,
                        content_category: 'subscription',
                        content_ids: [subscribedPlan.id],
                        num_items: 1,
                        subscription_credits: subscribedPlan.monthly_credits
                    });
                }

                navigate('/subscription', { replace: true });
                setShowSuccessModal(true);
            } catch (error) {
                console.error('Error handling subscription success:', error);
            }
        };

        if (location.search.includes('checkout=success')) {
            const planId = urlParams.get('plan');
            handleSuccessfulSubscription(planId);
        }
    }, [location, plans]);



    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            setLoading(true);
            const [plansRes, subscriptionRes, creditsRes] = await Promise.all([
                getSubscriptionPlans(),
                getCurrentSubscription(),
                getCreditsBalance()
            ]);

            setPlans(plansRes.data.plans);
            setCurrentSubscription(subscriptionRes.data.subscription);
        } catch (err) {
            setError('Não foi possível obter informações sobre planos. Por favor tente mais tarde, ou contacte-nos em geral@imagemai.com se o erro persistir.');
            console.error('Error fetching data:', err);
        } finally {
            setLoading(false);
        }
    };

    const handleSubscribe = async (plan) => {
        try {
            // Track checkout start with Facebook Pixel
            if (window.fbq) {
                window.fbq('track', 'InitiateCheckout', {
                    content_name: plan.name,
                    content_type: 'subscription',
                    currency: 'BRL',
                    value: plan.price,
                    content_ids: [plan.id],
                    num_items: 1
                });
            }

            // Create Checkout Session
            const response = await createCheckoutSession({ plan_id: plan.id });

            if (response.data.url) {
                // Redirect to Checkout
                window.location.href = response.data.url;
            }
        } catch (err) {
            console.error('Checkout error:', err);
            setError('Não foi possível iniciar o checkout. Por favor entre em contacto com geral@imagemai.com');
        }
    };



    const handleCancel = async () => {
        try {
            const response = await cancelSubscription();
            if (response.data.status === 'success') {
                await fetchData();
            } else {
                setError('Não foi possível cancelar a inscrição. Por favor tente mais tarde ou entre em contacto em geral@imagemai.com');
            }
        } catch (err) {
            console.error('Cancel error:', err);
            setError('Não foi possível cancelar a inscrição. Por favor tente mais tarde ou entre em contacto em geral@imagemai.com');
        }
    };

    const handleResume = async () => {
        try {
            const response = await resumeSubscription();
            if (response.data.status === 'success') {
                await fetchData();
            } else {
                setError('Failed to resume subscription');
            }
        } catch (err) {
            console.error('Resume error:', err);
            setError('Failed to resume subscription');
        }
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="text-xl">Loading...</div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="text-red-600">{error}</div>
            </div>
        );
    }

    return (
        <div className="flex flex-1 flex-col md:pl-64 h-full">
            <div className="container mx-auto px-4 py-8">
                <div className="mb-8 p-6 bg-zinc-900 rounded-lg text-white">
                    <h2 className="text-2xl font-bold mb-4">Planos</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <p className="text-gray-300">Créditos</p>
                            <p className="text-2xl font-bold">{credits}</p>
                        </div>
                        <div>
                            <p className="text-gray-300">O seu plano</p>
                            <p className="text-2xl font-bold">
                                {currentSubscription?.status !== 'canceled'
                                    ? currentSubscription?.plan_name
                                    : 'Nenhum plano'
                                }
                            </p>
                        </div>
                    </div>
                    {currentSubscription && (
                        <div className="mt-4">
                            {currentSubscription.status === 'active' ? (
                                <button
                                    onClick={handleCancel}
                                    className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition-colors"
                                >
                                    Cancelar plano
                                </button>
                            ) : currentSubscription.status === 'canceled' ? (
                                <div className="space-y-2">
                                    <p className="text-red-600 font-medium">Plano cancelado</p>
                                    <button
                                        onClick={handleResume}
                                        className="px-4 py-2 bg-lime-600 text-white rounded hover:bg-lime-700 transition-colors"
                                    >
                                        Reactivar
                                    </button>
                                </div>
                            ) : null}
                        </div>
                    )}
                </div>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    {plans.map((plan) => (
                        <div
                            key={plan.id}
                            className={`rounded-lg border p-6 ${currentSubscription?.plan_id === plan.id
                                ? 'border-lime-500 border-2 bg-zinc-900'
                                : 'border-gray-200 bg-zinc-900'
                                }`}
                        >
                            <h3 className="text-xl font-bold mb-2 text-white">{plan.name}</h3>
                            <p className="text-3xl font-bold mb-4 text-white">
                                R${plan.price}
                                <span className="text-sm text-gray-400">/mês</span>
                            </p>

                            <ul className="mb-6 space-y-2">
                                <li className="flex items-center text-white">
                                    <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                    </svg>
                                    {plan.monthly_credits} créditos (aprox. {Math.round(plan.monthly_credits / 10 * 4)} imagens)
                                </li>
                                {plan.credit_discount && (
                                    <li className="flex items-center text-white">
                                        <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                        </svg>
                                        {plan.credit_discount * 100}% desconto em créditos adicionais
                                    </li>
                                )}
                            </ul>

                            <button
                                onClick={() => handleSubscribe(plan)}
                                disabled={currentSubscription?.plan_id === plan.id && currentSubscription?.status === 'active'}
                                className={`w-full py-2 px-4 rounded-lg ${currentSubscription?.plan_id === plan.id && currentSubscription?.status === 'active'
                                    ? 'bg-gray-300 cursor-not-allowed'
                                    : 'bg-lime-600 hover:bg-lime-700 text-white'
                                    }`}
                            >
                                {currentSubscription?.plan_id === plan.id
                                    ? currentSubscription.status === 'active'
                                        ? 'Plano actual'
                                        : 'Reactivar'
                                    : 'Ativar plano'
                                }
                            </button>
                        </div>
                    ))}
                </div>
            </div>
            {showSuccessModal && (
                <SuccesModal
                    title="Plano atualizado"
                    body="Pode agora gerar mais imagens"
                    continue="Começar a gerar imagens"
                    navLink="/dashboard"
                />
            )}
        </div>
    );
}
