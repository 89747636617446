import React from 'react';
import bgImage from '../../Assets/lime_bg.png'

const images = [
    [
        { src: "https://autotube.fra1.digitaloceanspaces.com/Landingimages/Screenshot%202024-01-03%20at%2007.25.25.png", alt: 'Description 1' },
        { src: "https://autotube.fra1.digitaloceanspaces.com/Landingimages/Screenshot%202024-01-03%20at%2007.28.10.png", alt: 'Description 2' },
        { src: "https://autotube.fra1.digitaloceanspaces.com/Landingimages/Screenshot%202024-01-03%20at%2007.27.36.png", alt: 'Description 2' },
    ],
    [
        { src: "https://autotube.fra1.digitaloceanspaces.com/Landingimages/Screenshot%202024-01-03%20at%2007.24.53.png", alt: 'Description 2' },
        { src: "https://autotube.fra1.digitaloceanspaces.com/Landingimages/Screenshot%202024-01-03%20at%2007.24.31.png", alt: 'Description 2' },
        { src: "https://autotube.fra1.digitaloceanspaces.com/Landingimages/Screenshot%202024-01-03%20at%2007.28.34.png", alt: 'Description 2' },
    ],
    [
        { src: "https://autotube.fra1.digitaloceanspaces.com/Landingimages/Screenshot%202024-01-03%20at%2007.32.40.png", alt: 'Description 2' },
        { src: "https://autotube.fra1.digitaloceanspaces.com/Landingimages/Screenshot%202024-01-03%20at%2007.29.51.png", alt: 'Description 2' },
        { src: "https://autotube.fra1.digitaloceanspaces.com/Landingimages/Screenshot%202024-01-03%20at%2007.26.29.png", alt: 'Description 2' },
    ],
    [
        { src: "https://autotube.fra1.digitaloceanspaces.com/Landingimages/Screenshot%202024-01-03%20at%2007.30.48.png", alt: 'Description 2' },
        { src: "https://autotube.fra1.digitaloceanspaces.com/Landingimages/Screenshot%202024-01-03%20at%2007.33.20.png", alt: 'Description 1' },
        { src: "https://autotube.fra1.digitaloceanspaces.com/Landingimages/Screenshot%202024-01-03%20at%2007.23.27.png", alt: 'Description 2' },
    ]
];

function parallaxEffect() {
    const parallaxColumns = document.querySelectorAll('.parallax-column');
    parallaxColumns.forEach(column => {
        let speed = column.getAttribute('data-speed');
        column.style.transform = `translateY(${window.scrollY * speed}px)`;
    });
}

window.addEventListener('scroll', parallaxEffect);

const Gallery = () => {
    const margins = ['mt-12', '-mt-20', 'mt-24', '-mt-24']
    const paralax_speed = ['-0.05', '0.09', ' -0.07', '0.05']

    return (
        <div className="relative isolate overflow-hidden bg-cover bg-center bg-no-repeat w-screen z-10"
            style={{ backgroundImage: `url(${bgImage})` }}
        >
            <div className='z-10 relative text-center'>
                <h1 className='text-white text-4xl xl:text-7xl font-bold pt-24'>De imaginação para</h1>
                <p className="text-4xl xl:text-7xl font-bold inline-block text-transparent bg-clip-text bg-gradient-to-br from-lime-500 via-lime-600 to-blue-600">realidade</p>
            </div>
            <div className='flex justify-center z-0 pb-12 md:pb-48'>
                <div className="flex max-w-5xl justify-center mt-8">
                    {images.map((image, index) => {
                        // Only render first two columns on medium screens and below
                        if (index < 2 || window.innerWidth >= 768) {
                            return (
                                <div
                                    key={index}
                                    className={`flex flex-col parallax-column ${margins[index]} ${index >= 2 ? 'hidden lg:flex' : ''}`}
                                    data-speed={paralax_speed[index]}
                                >
                                    {image.map((current_image) => (
                                        <div key={current_image.src} className="p-2">
                                            <img
                                                src={current_image.src}
                                                alt={current_image.alt}
                                                className="w-full aspect-square object-cover rounded-3xl shadow-lg"
                                            />
                                        </div>
                                    ))}
                                </div>
                            );
                        }
                        return null;
                    })}
                </div>
            </div>
        </div>
    );
};

export default Gallery;
