import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Ratio } from "lucide-react";
import {
  Bars3Icon,
  HomeIcon,
  BuildingLibraryIcon,
  RectangleStackIcon,
  XMarkIcon,
  StopCircleIcon,
} from "@heroicons/react/24/outline";
import logo from "../../Assets/logo.png";
import { useNavigate } from "react-router-dom";
import Plans from "./Settings/Payments/ViewPlans";
import BillingController from "./Settings/Payments/BillingController";
import UserSettings from "./Settings/UserSettings";
import SideBar from "../../Components/SideBar";
import { generateImage, getImages } from "../../Requests/ImageRequests";
import MyImageGalery from "../../Components/MyImageGalery";
import Loader from "../../Components/Loader";
import { useCredits } from "../../utils/creditsManage";
import NoCreditsModal from "../../Components/NoCreditsModal";

const navigation = [
  { name: "Home", href: "#", icon: HomeIcon, current: true },
  { name: "Palaces", href: "#", icon: BuildingLibraryIcon, current: false },
  { name: "Runs", href: "#", icon: RectangleStackIcon, current: false },
];

const ratioOptions = [
  { display: "16:9", value: "16:9" },
  { display: "1:1", value: "1:1" },
  { display: "9:16", value: "9:16" }
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Dashboard() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [mountedElement, setMoutedElement] = useState(<UserSettings />);
  const [creatingImages, setCreatingImages] = useState(false);
  const [createImagesError, setCreateImagesError] = useState(false);
  const [prompt, setPrompt] = useState('');
  const [currentRatioIndex, setCurrentRatioIndex] = useState(1);
  const [aspectRatio, setAspectRatio] = useState("1:1");
  const navigate = useNavigate();
  const { credits, removeCredits } = useCredits();
  const [errorModal, setErrorModal] = useState(null);

  const handleRatioToggle = () => {
    const nextIndex = (currentRatioIndex + 1) % ratioOptions.length;
    setCurrentRatioIndex(nextIndex);
    setAspectRatio(ratioOptions[nextIndex].value);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    generateImage({
      "prompt": prompt + " --ar " + aspectRatio,
      "aspectRatio": aspectRatio
    }).then((res) => {
      setCreatingImages(true);
      setCreateImagesError(false);
    }).catch((err) => {
      console.log(err);
      if (err?.response?.data?.message == "No credits") {
        setErrorModal(
          <NoCreditsModal
            isOpen={true}
            onClose={() => { setErrorModal(null) }}
            onViewPlans={() => { navigate("/subscription") }}
          />
        );
      }
    });

    removeCredits(10);
  };

  return (
    <>
      <div className="h-full">
        <div className="flex flex-1 flex-col md:pl-64 h-full">
          <main className="flex-1 h-full">
            <div className="py-6 h-full">
              <div className="mx-auto px-4 sm:px-6 lg:px-8 flex flex-col justify-center items-center">
                <form className="flex gap-2 w-full h-full max-w-7xl flex-col sm:flex-row items-center justify-center" onSubmit={handleFormSubmit}>

                  <input
                    id="prompt"
                    name="prompt"
                    type="text"
                    placeholder="Abelha astronauta no espaço"
                    value={prompt}
                    onChange={(e) => setPrompt(e.target.value)}
                    required
                    className="block h-full w-full appearance-none rounded-md border border-gray-300 px-3 py-3 placeholder-gray-400 shadow-sm focus:border-lime-500 focus:outline-none focus:ring-lime-500 sm:text-sm"
                  />
                  {creatingImages ? (
                    <div className="bg-gradient-to-br from-lime-600 to-blue-800 rounded-lg px-4  text-white w-full sm:w-36 h-12 flex justify-center items-center">
                      <Loader />
                    </div>
                  ) : (
                    <button className="bg-gradient-to-br from-lime-700 to-blue-800 rounded-lg px-4 y-2 text-white w-full sm:w-36 h-12">
                      Gerar
                    </button>
                  )}
                </form>
                <button
                  type="button"
                  onClick={handleRatioToggle}
                  className="flex  items-center p-2 gap-2 rounded-md hover:bg-gray-100 transition-colors"
                >
                  <Ratio className="w-5 h-5 text-gray-400" />
                  <span className="text-xs text-gray-400 mt-1">
                    {ratioOptions[currentRatioIndex].display}
                  </span>
                </button>
                <div className="py-8">
                  <MyImageGalery
                    creatingImages={creatingImages}
                    setCreatingImages={setCreatingImages}
                    createImagesError={createImagesError}
                    setCreateImagesError={setCreateImagesError}
                    setPrompt={setPrompt}
                  />
                </div>
              </div>
            </div>
          </main>
        </div>
        {errorModal}
      </div>
    </>
  );
}
