import React, { useState } from 'react';
import { Eye, EyeOff } from 'lucide-react';

const PasswordInput = ({ id, name, label, required = true, className = "" }) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <div className="space-y-1">
            <label htmlFor={id} className="block text-sm font-medium text-white">
                {label}
            </label>
            <div className="mt-1 relative">
                <input
                    id={id}
                    name={name}
                    type={showPassword ? "text" : "password"}
                    required={required}
                    className={`block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-lime-500 focus:outline-none focus:ring-lime-500 sm:text-sm pr-10 ${className}`}
                />
                <button
                    type="button"
                    className="absolute inset-y-0 right-0 flex items-center pr-3"
                    onClick={() => setShowPassword(!showPassword)}
                >
                    {showPassword ? (
                        <EyeOff className="h-5 w-5 text-gray-400" />
                    ) : (
                        <Eye className="h-5 w-5 text-gray-400" />
                    )}
                </button>
            </div>
        </div>
    );
};

export default PasswordInput;
