import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  cancelSubscriptionRenewal,
  getSubscriptions,
  reactivateSubscriptionRenewal,
} from "../../../../Requests/User/UserRequests";
import {
  CheckCircleIcon,
  ChevronRightIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import Moment from "moment";
import { useParams, useSearchParams } from "react-router-dom";
import SuccesModal from "../../../../Components/SuccesModal";
import { sendAmplitudeData } from "../../../../utils/amplitude";
import Loader from "../../../../Components/Loader";

function ViewSubscriptions() {
  const { t } = useTranslation();
  const [subscriptions, setSubscriptions] = useState(null);
  const [sucessModal, setSucessModal] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  function viewcurrentSubscriptions() {
    let response = getSubscriptions();
    response
      .then((res) => {
        if (res.data) {
          setIsLoading(false);
          setSubscriptions([res.data]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }

  const removeQueryParams = () => {
    const client_secret = searchParams.get("payment_intent_client_secret");
    const payment_intent = searchParams.get("payment_intent");
    const redirect_status = searchParams.get("redirect_status");

    client_secret && searchParams.delete("payment_intent_client_secret");
    payment_intent && searchParams.delete("payment_intent");
    redirect_status && searchParams.delete("redirect_status");

    if (client_secret || payment_intent || redirect_status) {
      setSearchParams(searchParams);
    }
    if (redirect_status == "succeeded") {
      setSucessModal(
        <SuccesModal
          title={t('Billing.PaymentSucessModalTitle')}
          body={t('Billing.PaymentSucessModalDescription')}
          continue={t('Billing.PaymentSucessModalButton')}
        />
      );
    }
  };

  useEffect(() => {
    viewcurrentSubscriptions();
    removeQueryParams();
    sendAmplitudeData({ "type": "Navigation", "value": "User Plans" })

  }, []);

  const subscriptionCancel = (event) => {
    setIsLoadingAction(true)
    cancelSubscriptionRenewal(
      event.target.id
    ).then((res) => {
      sendAmplitudeData({ "type": "Action", "value": "Cancel subscription" })
      window.location.reload(false);
    }).error(() => {
      setIsLoadingAction(false)

    });
  }

  const subscriptionReactivate = (event) => {
    setIsLoadingAction(true)
    reactivateSubscriptionRenewal(
      event.target.id
    ).then((res) => {
      sendAmplitudeData({ "type": "Action", "value": "Reactivate Subscription" })
      window.location.reload(false);
    }).then((error) => {
      setIsLoadingAction(false)

    });
  }


  return (
    <div>
      {isLoading ? (
        <div className="flex justify-center items-center  my-24">
          <div
            className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
            role="status"
          >
            <span className="visually-hidden"></span>
          </div>
        </div>
      ) : (
        <div>
          {subscriptions ? (
            <div className="overflow-hidden  sm:rounded-md my-8 mx-8">
              <h1 className="text-4xl font-semibold text-white-900 my-8 mx-8 text-left">
                {t("Billing.UserPlansTabTitle")}
              </h1>
              <h1 className="text-xl  text-white-900 my-8 mx-8 text-left">
                {t("Billing.UserPlansTabDescription")}
              </h1>
              <ul
                role="list"
                className="divide-y shadow divide-gray-200 bg-white"
              >
                {subscriptions.map((subscription) => (
                  <li key={subscription.id}>
                    <a className="block hover:bg-gray-50">
                      <div className="flex items-centerpy-4 px-2 py-8 sm:px-6">
                        <div className="flex min-w-0 flex-1 items-center">
                          <div className="min-w-0 flex-1 md:grid md:grid-cols-6 md:gap-4">
                            <div>
                              {subscription.plan.active &&
                                !subscription.cancel_at_period_end &&
                                !subscription.canceled_at && (
                                  <span className="rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">
                                    {t("General.Actions.Active")}
                                  </span>
                                )}
                              {subscription.cancel_at_period_end && (
                                <span className="rounded-full bg-gray-200 px-2.5 py-0.5 text-xs font-medium text-white-800">
                                  {t("General.Actions.Inactive")}
                                </span>
                              )}
                              {subscription.canceled_at &&
                                !subscription.cancel_at_period_end && (
                                  <span className="rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-medium text-red-800">
                                    {t("General.Actions.Canceled")}
                                  </span>
                                )}
                            </div>
                            <div>
                              <p className="text-sm font-medium text-lime-600">
                                {subscription.plan.nickname}
                              </p>
                            </div>
                            <div>
                              <p className="text-sm text-white-900">
                                {t("Billing.StartedOn")}:{" "}
                                <span>
                                  {Moment.unix(
                                    subscription.current_period_start
                                  ).format("DD-MM-YYYY")}
                                </span>
                              </p>
                            </div>
                            <div>
                              <p className="text-sm text-white-900">
                                {t("Billing.EndedOn")}:{" "}
                                <span>
                                  {subscription.canceled_at
                                    ? t("Billing.NoRenewal")
                                    : Moment.unix(
                                      subscription.current_period_end
                                    ).format("DD-MM-YYYY")}
                                </span>
                              </p>
                            </div>
                            <div>
                              <p className=" text-sm text-white-500">
                                <span className="truncate">
                                  {subscription.plan.amount / 100}{" "}
                                  {subscription.plan.currency}
                                </span>
                              </p>
                            </div>
                            <div className="mx-2">
                              {subscription.canceled_at &&
                                !subscription.cancel_at_period_end ? (
                                <button
                                  className="truncate text-sm text-purple-400"
                                  onClick={() => {
                                    navigate("/plans");
                                    window.location.reload(false);
                                  }}
                                >
                                  {t("General.Actions.BuyAgain")}
                                </button>
                              ) : subscription.cancel_at_period_end ? (
                                <button
                                  className="truncate text-sm text-green-600"
                                  id={subscription.id}
                                  onClick={subscriptionReactivate}
                                >
                                  {isLoadingAction ? <Loader /> : t("General.Actions.Renew")}
                                </button>
                              ) : (
                                <button
                                  className="truncate text-sm text-red-400"
                                  name="subscription"
                                  id={subscription.id}
                                  onClick={subscriptionCancel}
                                >
                                  {isLoadingAction ? <Loader /> : t("General.Actions.Cancel")}
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <div className="my-8">
              <h1 className="text-4xl font-semibold text-white-900 my-8 mx-8 text-left">
                {t("Billing.UserPlansTabTitle")}
              </h1>
              <h1 className="text-xl  text-white-900 my-8 mx-8 text-left">
                {t("Billing.UserPlansTabDescription")}
              </h1>
              <svg
                className="mx-auto h-12 w-12 text-white-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  vectorEffect="non-scaling-stroke"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                />
              </svg>
              <h3 className="mt-2 text-sm font-medium text-white-900">
                {t("Billing.NoActivePlans")}
              </h3>
              <p className="mt-1 text-sm text-white-500">
                {t("Billing.NoActivePlansDescription")}
              </p>
              <div className="mt-6">
                <button
                  onClick={() => {
                    navigate("/plans", { replace: true });
                    window.location.reload();
                  }}
                  type="button"
                  className="inline-flex items-center rounded-md border border-transparent bg-lime-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-lime-500 focus:ring-offset-2"
                >
                  <svg
                    className="-ml-1 mr-2 h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
                  </svg>
                  {t("Billing.NewPlanButton")}
                </button>
              </div>
            </div>
          )}
        </div>
      )}
      {sucessModal}
    </div>
  );
}

export default ViewSubscriptions;
