import { AdjustmentsVerticalIcon, ArrowPathIcon, CloudArrowUpIcon, LockClosedIcon, MicrophoneIcon, PaperAirplaneIcon, PencilIcon, PhotoIcon, VideoCameraIcon, SparklesIcon } from '@heroicons/react/20/solid'
import React from 'react'

const features = [
    {
        name: 'Alta Qualidade Garantida',
        description:
            'Gere imagens em alta resolução, com detalhes nítidos e cores vibrantes, ideais para qualquer projeto profissional.',
        href: '#',
        icon: PhotoIcon,
    },
    {
        name: 'Múltiplas Orientações',
        description:
            'Crie imagens no formato que precisar: quadradas, horizontais ou verticais, perfeitamente adaptadas para diferentes plataformas e usos.',
        href: '#',
        icon: AdjustmentsVerticalIcon,
    },
    {
        name: 'Interface em Português',
        description:
            'Plataforma totalmente em português, facilitando seu trabalho com uma experiência completamente localizada para o mercado brasileiro.',
        href: '#',
        icon: PencilIcon,
    },
    {
        name: 'Tecnologia de Ponta',
        description:
            'Utilize a mais avançada tecnologia de IA para geração de imagens, garantindo resultados superiores e originais.',
        href: '#',
        icon: MicrophoneIcon,
    },
    {
        name: 'Fácil de Usar',
        description:
            'Interface intuitiva que permite criar imagens impressionantes em poucos cliques, sem necessidade de conhecimento técnico.',
        href: '#',
        icon: SparklesIcon,
    }
]

function Features() {
    return (
        <div className="bg-gradient-to-b from-black to-zinc-800 py-24 sm:py-32 -mt-32 md:-mt-64 relative z-20">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:text-center">
                    <h2 className="text-base font-semibold leading-7 text-lime-400">Geração de imagens simplificada</h2>
                    <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
                        Crie imagens incríveis em segundos
                    </p>
                    <p className="mt-6 text-lg leading-8 text-gray-300">
                        Uma solução completa em português para gerar imagens de alta qualidade com inteligência artificial
                    </p>
                </div>
                <div className="text-start mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
                    <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                        {features.map((feature) => (
                            <div key={feature.name} className="flex flex-col">
                                <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-white">
                                    <feature.icon className="h-5 w-5 flex-none text-lime-400" aria-hidden="true" />
                                    {feature.name}
                                </dt>
                                <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-300">
                                    <p className="flex-auto">{feature.description}</p>
                                </dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
}

export default Features
